import React, { useEffect, useRef, useState } from "react";
import "../Send/Send.css";
import { FaQrcode } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import QrScanner from "qr-scanner";

const ScanQR = () => {
  const navigate = useNavigate();

  const videoRef = useRef();
  const photoRef = useRef();

  const [globalStream, setGlobalStream] = useState(undefined);
  const [scannerData, setScannerData] = useState("");

  function nav(move) {
    try {
      const currentIndex = document.activeElement.tabIndex;
      const next = currentIndex + move;
      const items = document.querySelectorAll(".items");
      const targetElement = items[next];
      targetElement.focus();
    } catch (e) {
      console.log("Home Error:", e);
    }
  }

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeydown);
    return () => document.body.removeEventListener("keydown", handleKeydown);
  }, []);

  function handleKeydown(e) {
    e.stopImmediatePropagation();
    switch (e.key) {
      case "ArrowUp":
        nav(-1);
        break;
      case "ArrowDown":
        nav(1);
        break;
    }
  }

  useEffect(() => {
    try {
      let cameras = navigator.mozCameras.getListOfCameras();
      navigator.mozCameras.getCamera(cameras[0]).then(success, showError);
    } catch (error) {
      alert("Moz camera not supported for this device");
    }
  }, []);

  useEffect(() => {
    // Prevent the Arrow up down of amount field
    try {
      document
        .getElementById("amount-input")
        .addEventListener("keydown", function (e) {
          if (e.which === 38 || e.which === 40) {
            e.preventDefault();
          }
        });
    } catch (error) {}
  }, []);

  // Take Photo
  const takePhoto = () => {
    const width = 414;
    const height = width / (16 / 9);

    let video = videoRef.current;
    let photo = photoRef.current;

    photo.width = width;
    photo.height = height;

    let ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, width, height);

    const canvas = document.getElementById("canvas");
    let image = canvas.toDataURL();
    blobImg(image);
  };

  // Create a blob img
  async function blobImg(url) {
    const pngUrl = url;
    const blob = await (await fetch(pngUrl)).blob();
    // Create file form the blob
    const image = new File([blob], "qr-code.png", { type: blob.type });
    // Share Code
    try {
      readQrCode(image);
    } catch (error) {
      console.log(error);
    }
  }

  // Stop the camera stream
  function stopCamera() {
    try {
      globalStream.getTracks().forEach(function (track) {
        track.stop();
      }); 
    } catch (error) {
      console.log(error);
    }
  }

  // New Scanner Function for qr img
  const readQrCode = (file) => {
    if (!file) {
      return;
    }
    QrScanner.scanImage(file, { returnDetailedScanResult: true })
      .then((result) => {
        try {
          if (result.data) {
            checkValidData(result.data);
          }
        } catch (error) {
          console.log(error);
        }
      })
      .catch((e) => setScannerData(""));
  };

  function success(stream) {
    let videoElement = videoRef.current;
    let camera = stream.camera;
    videoElement.srcObject = stream.camera;
    let angle = camera.sensorAngle;
    let transform = "";
    transform += "rotate(" + angle + "deg)";

    // videoElement.style.height = `${currentWidth}px`;
    videoElement.style.transform = transform;
    videoElement.style.height = `calc(100vw - 50px)`;

    videoElement.play();
    setGlobalStream(stream.camera);
  }

  function showError(error) {
    console.log(error);
  }

  const checkValidData = (data) => {
    if (data.includes("kaifoundry.com")) {
      let newUrl = new URL(data);
      stopCamera();
      navigate(newUrl.pathname + newUrl.search);
    } else {
      alert("Please Scan a valid QR code");
    }
  };

  return (
    <div className="send-page">
      <div className="header">
        <button
          className="items"
          tabIndex={0}
          onClick={() => stopCamera() + navigate(-1)}
        >
          <BsArrowLeft />
        </button>{" "}
        <strong>Scan QR Code</strong>
        <strong>
          <FaQrcode />
        </strong>
      </div>

      {!scannerData && (
        <div className="scanner-div">
          <div className="container">
            <div className="camera">
              <video ref={videoRef}></video>
            </div>
            <div className="btn">
              <button
                className="items"
                tabIndex={1}
                onClick={() => takePhoto()}
              >
                Capture Qr Code
              </button>
            </div>
            <canvas
              style={{ display: "none" }}
              id="canvas"
              ref={photoRef}
            ></canvas>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScanQR;
